import React, { useEffect, useState } from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Anchor, Divider, Grid, Group, LoadingOverlay, Table, Text} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MainLayout } from '../../layouts'
import { useAppDispatch, useAppSelector } from '../../../store'
import { CompanyType } from '../../../store/types'
import {BackListButton, ContentBox, ContentNotFound, ItemActionsMenu} from '../../partials'
import { fetchShowCompany, resetShowCompany } from '../../../store/features/companies/showCompanySlice'
import { fetchDeleteCompany, resetDeleteCompany } from '../../../store/features/companies/deleteCompanySlice'
import Moment from "react-moment";
import {DATETIME_FORMAT} from "../../../types";

const Show = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [data, setData] = useState<CompanyType | null>(null)
  const { isLoading, response, error } = useAppSelector(state => state.showCompany)
  const {
    isLoading: deleteIsLoading,
    response: deleteResponse,
    error: deleteError
  } = useAppSelector(state => state.deleteCompany)

  useEffect(() => {
    if (response === null && data === null) {
      ;(async () => {
        await dispatch(fetchShowCompany(id as string))
      })()
    }
  }, [response, data, id, dispatch])

  useEffect(() => {
    if (response !== null) {
      setData(response.data)

      if (data !== null) {
        dispatch(resetShowCompany())
      }
    }
  }, [response, dispatch, data])

  useEffect(() => {
    if (error !== null) {
      if (error.response.status === 401 && id) {
        ;(async () => {
          dispatch(resetShowCompany())
          await dispatch(fetchShowCompany(id))
        })()
      } else if (error.response.status === 404) {
        dispatch(resetShowCompany())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t(error.data.message)
        })

        navigate('/companies')
      } else {
        dispatch(resetShowCompany())
        dispatch(resetDeleteCompany())
        showNotification({
          color: 'red',
          title: t('error'),
          message: t('errors.unknown')
        })

        navigate('/companies')
      }
    }
  }, [error, id, dispatch, t, navigate])

  useEffect(() => {
    if (deleteResponse !== null) {
      dispatch(resetShowCompany())
      dispatch(resetShowCompany())

      showNotification({
        color: 'green',
        title: t('successful'),
        message: t('deleted')
      })

      navigate('/companies')
    }
  }, [deleteResponse, t, dispatch, navigate])

  useEffect(() => {
    if (deleteError !== null) {
      dispatch(resetShowCompany())

      showNotification({
        color: 'red',
        title: t('unsuccessful'),
        message: deleteError.response.data ? deleteError.response.data.message : t('errors.unknown')
      })
    }
  }, [deleteError, t, dispatch])

  const handleDelete = async (id: string) => {
    await dispatch(fetchDeleteCompany(id))
  }

  return (
    <MainLayout>
      <LoadingOverlay visible={isLoading || deleteIsLoading} />
      {data && (
        <>
          <ContentBox title={t('content_actions.show')}>
            <h4>{t('id')}:</h4>
            <Text>{data.id}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('name')}:</h4>
            <Text>{data.name}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('package')}:</h4>
            <Anchor
              component={NavLink}
              to={`/packages/${data.package.id}`}
            >
              <span>{data.package.name}</span>
            </Anchor>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('email')}:</h4>
            <Text>{data.email ? data.email : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('person')}:</h4>
            <Text>{data.person ? data.person : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('phone')}:</h4>
            <Text>{data.phone ? data.phone : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('state')}:</h4>
            <Text>{data.state ? data.state.name : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('city')}:</h4>
            <Text>{data.city ? data.city.name : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('address')}:</h4>
            <Text>{data.address ? data.address : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('zip')}:</h4>
            <Text>{data.zip ? data.zip : '-'}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('employees')}:</h4>
            <Text>
              {data.employees.length ? (
                <div className="table-responsive">
                  <Table
                    striped
                    highlightOnHover
                  >
                    <thead>
                    <tr>
                      <th>{t('user')}</th>
                      <th className="text-center">{t('role')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.employees.map((employee, key) => (
                      <tr key={key}>
                        <td>
                          <Anchor
                            component={NavLink}
                            to={`/users/${employee.user.id}`}
                          >
                            <span>{employee.user.last_name}, {employee.user.name} - {employee.user.email}</span>
                          </Anchor>
                        </td>
                        <td className="text-center">{employee.is_admin ? t('admin') : t('employee')}</td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <ContentNotFound/>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('monthly_average_fleet_size')}:</h4>
            <Text>{data.monthly_average_fleet_size}</Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('created_at')}:</h4>
            <Text>
              {data.created_at ? (
                <Moment format={DATETIME_FORMAT}>{data.created_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>

            <Divider
              variant="dashed"
              size="xs"
              mt="sm"
            />

            <h4>{t('updated_at')}:</h4>
            <Text>
              {data.updated_at ? (
                <Moment format={DATETIME_FORMAT}>{data.updated_at}</Moment>
              ) : (
                <>-</>
              )}
            </Text>
          </ContentBox>
          <ContentBox>
            <Grid>
              <Grid.Col span={6}>
                <BackListButton to="/companies" />
              </Grid.Col>
              <Grid.Col span={6}>
                <Group
                  position="right"
                  pr="md"
                >
                  <ItemActionsMenu
                    edit={`/companies/${data.id}/edit`}
                    onDelete={() => handleDelete(data.id)}
                  />
                </Group>
              </Grid.Col>
            </Grid>
          </ContentBox>
        </>
      )}
    </MainLayout>
  )
}

export default Show
